import * as Yup from "yup";
import { addDays, isBefore, parse } from "date-fns";
export var LeaveReviewSchema = Yup.object().shape({
    venueID: Yup.string(),
    user: Yup.object().shape({
        emailAddress: Yup.string().email('Please enter a valid email').required('Required'),
        name: Yup.string().required("Required"),
        hideSurname: Yup.boolean()
    }),
    venue: Yup.object().shape({
        id: Yup.string().nullable(),
        name: Yup.string(),
        address: Yup.object().shape({
            addressLine1: Yup.string(),
            addressLine2: Yup.string(),
            addressLine3: Yup.string(),
            county: Yup.string(),
            postcode: Yup.string(),
            latLng: Yup.string(),
            placeID: Yup.string()
        }),
        image: Yup.mixed(),
        description: Yup.string(),
        website: Yup.string().nullable(),
        telephoneNumber: Yup.string().nullable(),
    }),
    review: Yup.object().shape({
        dateOfVisit: Yup.string().test('check-future-date', 'Please select a date in past', function (value) {
            var maxDate = addDays(new Date(), 1);
            var date = parse(value, 'yyyy-MM-dd', new Date());
            return isBefore(date, maxDate);
        }).required('Required'),
        rating: Yup.number().moreThan(0, 'Please select a rating').required('Required'),
        title: Yup.string().required('Required'),
        content: Yup.string().required('Required'),
        menuTopTips: Yup.string(),
        venueTopTips: Yup.string(),
        recommendation: Yup.string(),
        allergy: Yup.array(Yup.string()).min(1, 'You must select one allergy.'),
    }),
    acceptTerms: Yup.boolean().isTrue('You must accept the terms and conditions')
});
export var LeaveReviewTouched = {
    user: {
        emailAddress: true,
        name: true,
    },
    review: {
        dateOfVisit: true,
        rating: true,
        title: true,
        content: true,
        allergy: true
    },
    acceptTerms: true
};
